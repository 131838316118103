import { Atom } from ":mods";


export function createRegionInfoInputs(){
  const region = Atom.Form.createInput("", {
    required: true,
  });


  const Actions = Atom.Form.createFormActions({
    region,
   
  });
  return {
    Actions,
    region,
  }
}